<template>
  <div>
    <Header />
    <v-row>
      <v-col class="parceiros">
        <div>Visite nossos parceiros</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <a href="https://www.facebook.com/daniel.goulart.7923" target="_blank">
          <img width="300px" :src="require('@/assets/parcdanielprod.png')" />
        </a>
      </v-col>
      <v-col class="mb-15">
        <a href="https://www.neoti.com.br" target="_blank">
          <img width="300px" :src="require('@/assets/parcneoti.png')" />
        </a>
      </v-col>
    </v-row>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/HeaderGeral.vue";
import Carousel from "../components/Carousel.vue";
import Footer from "../components/Footer.vue";

export default {
  components: {
    Header,
    Carousel,
    Footer,
  },
};
</script>
<style scoped>
.parceiros {
  font-size: 40px;
  font-weight: bold;
  margin-top: 100px;
  margin-bottom: 100px;
  color: red;
}
.texto {
  margin-top: 50px;
}
.vermelho {
  color: red;
}
.index {
  z-index: 1;
}
</style>
